<template>
  <div class="row">
    <div class="col-md-10">
      <card class="stacked-form">
        <div class="text-center">
          <img
            src="https://imgix.cosmicjs.com/b03c5cf0-f402-11eb-aa23-db5c20501154-image.png"
            height="120px"
            align="top"
          />
          <h4
            class="tulum-party-subtitle mt-0 mb-10"
            style="letter-spacing: 0.4em; font-size: 1.4rem; margin-top: 1px"
          >
            <<b>Get in touch</b>>
          </h4>
        </div>
        <hr />
        <p class="font-weight-bold">
          Do you need recommendations from a neutral point of view? Need help
          getting tickets or prepare your trip?
        </p>
        <small class="text-white">
          We are specialist on tripping around the riviera maya.
        </small>
        <hr />
        <p class="font-weight-bold">
          Are you traveling alone and want to meet others?
        </p>
        <small class="text-white"> We love connecting solo travelers. </small>
        <hr />
        <p class="font-weight-bold">Are you promoting a legal event?</p>
        <small class="text-white">
          Send us your info, we are promoting events by free (if there's any?)
        </small>
        <hr />
        <p class="font-weight-bold">Are you a digital nomad?</p>
        <small class="text-white">
          We are Mexican digital nomads without the will to move
        </small>
        <hr />
        <p class="font-weight-bold">
          Do you like to party but not much ? (ya estas viejo?)
        </p>
        <small class="text-white">
          There's much more than party, specially around the riviera maya.
          Looking for recommendations (natural places, food ..) ?
        </small>
        <hr />

        <p class="font-weight-bold">Are you looking for an spiritual answer?</p>
        <small class="text-white"> We too that's why we love tulum</small>
        <hr />

        <a href="mailto:ronnie@tulum.party">Contact Us</a>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo: () => ({
    title: '[tulum 2021]',
    titleTemplate: '%s | Contact Us ',
    meta: [
      { name: 'robots', content: 'noindex' },
      {
        name: 'description',
        content: 'Tulum Parties, Nightlife and Techno Music.',
      },
      {
        name: 'keywords',
        content:
          'Tulum, nightlife, Techno,  clubs, Tonight, Zamna, 2021, Papaya Playa, Fullmoon, Events, Bagatelle',
      },
    ],
  }),
  data: () => {
    return {
      modal: {},
      invitationId: null,
      name: null,
      email: null,
      message: null,
      invitationSubmitted: false,
      events: [],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
